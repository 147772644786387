.content-container {
  margin: auto;
  max-width: 400px;
  width: 100%;
  margin-top: 50px;
  height: fit-content;
  background-color: #f4f4f4;
  font-family: "IBM Plex Sans";
  padding: 20px;
  border-radius: 5px;
}

.title {
  font-size: 20px;
  margin: 10px 0 10px 0;
}

.desc {
  font-size: 14px;
  margin-bottom: 20px;
  color: #525252;
  line-height: 19px;
}

.img-container {
  display: flex;
  justify-content: center;
}

.img-container img {
  width: 80%;
}

input {
  background-color: white !important;
  margin-bottom: 20px;
}

.result {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: #161616;
  font-size: 20px;
}

.content-container a {
  text-decoration: underline;
  color: #525252;
}

.watermark {
  position: fixed;
  bottom: -70px;
  right: -70px;
  opacity: 0.2;
  height: 250px;
  cursor: pointer;
}

.love {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  font-size: 14px;
}

.love a {
  color: black;
}

.rotating {
  -webkit-animation: rotating 60s linear infinite;
  animation: rotating 60s linear infinite;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.description-container {
  max-width: 820px;
  margin: auto;
  padding: 40px;
}

.description-container p {
  padding: 8px 0;
}

.description-container .formula-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  flex-direction: column;
}

.description-container .formula-container img {
  height: 40px;
}

i {
  font-style: italic !important;
}

sup {
  position: relative;
  font-size: 10px !important;
}

sup div {
  position: absolute;
  left: 14px;
  top: 0;
}

ul li {
  font-size: 13px;
  padding: 4px 0;
}

.show-calculator button,
.show-calculator a {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  background: #f4f4f4;
  padding: 12px;
  border-radius: 7px;
  font-size: 14px;
  margin-top: 20px;
  transition: 0.3s;
  text-decoration: none;
  color: black;
}

.show-calculator button:hover,
.show-calculator a:hover {
  background-color: #d8d8d8;
}

.show-calculator.blog {
  margin: 20px auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin: 10px 0;
}

b {
  font-weight: bold !important;
}

ol {
  margin: 20px !important;
  font-size: 14px !important;
}
